/*
Category: Base
*/

body,
html {
  min-height: 100vh;
  width: 100%;
  margin: 0px;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}

main {
  height: 100%;
  box-sizing: border-box;
}

svg {
  height: 36px !important;
  width: 36px !important;
  box-sizing: inherit;
}

button {
  margin-top: 0px !important;
  box-sizing: inherit;
}

p {
  margin: 0;
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

a:hover {
  color: #f05f40;
}

.bg-primary {
  background-color: #F05F40 !important;
}

.bg-dark {
  background-color: #212529 !important;
}

/*
  Category: NavigationBar
*/

.navbar {
  width: 100%;
  padding: 0 0 0 0;
  z-index: 1;
  position: fixed;
  box-sizing: inherit;
}

/*
  Category: Divs
*/

.root {
  text-align: center;
  box-sizing: inherit;
}

.App {
  margin: 0;
  padding: 0;
  display: flex;
}

.page-parent {
  height: 100vh;
  width: 100%;
  padding: 1em;
  padding-top: calc(1em + 64px);
  box-sizing: border-box;
  overflow: auto;
}

.output-parent {
  height: 100vh;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  overflow: unset;
  display: grid;
  place-items: center;
}

.sidebar {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.container-full {
  height: auto;
  min-height: 100%;
}

.container {
  width: 100%;
  height: 100%;
  padding: 0 0 0 0;
  overflow: auto;
  box-sizing: inherit;
}

.container-form {
  width: -moz-fit-content;
  height: -moz-fit-content;
  margin: auto;
  align-content: center;
  box-sizing: inherit;
}

.container-center {
  width: -moz-fit-content;
  height: -moz-fit-content;
  margin: 0 auto;
  box-sizing: inherit;
}

.container-output {
  width: 100%;
  height: 100%;
  box-sizing: inherit;
}

.container-dialer {
  width: -moz-fit-content;
  height: -moz-fit-content;
  margin: auto auto;
  box-sizing: inherit;
}

.container-formwrap {
  width: fit-content;
  padding: 0 20px 0 20px;
  box-sizing: inherit;
}

.container-outputleft {
  width: 75%;
  height: 100%;
  margin: auto 0;
  padding-left: 16px;
  float: left;
  box-sizing: inherit;
}

.container-outputright {
  border-left: 1px solid #000;
  width: 25%;
  height: 100%;
  margin: auto 0;
  padding-left: 16px;
  float: right;
  box-sizing: inherit;
}


.container-verse {
  height: 100%;
}

.wrap-main {
  width: 100%;
}

.wrap-verse {
  height: 12%;
  width: 50%;
}

.basic-paragraph {
  text-align: center;
  box-sizing: inherit;
}

.welcome-buttons {
  width: -moz-fit-content;
  height: -moz-fit-content;
  margin: auto;
  box-sizing: inherit;
}

.userform {
  display: grid;
  box-sizing: inherit;
}

.authentication-form {
  align-content: center;
  height: fit-content;
  box-sizing: inherit;
}

/*
  Category: Headers and Footers
*/

.header {
  width: 100%;
  padding: 0 0 0 0;
  position: fixed;
  box-sizing: inherit;
}

/*
  Category: Footers
*/

.footer {
  text-align: center;
}

/*
  Category: h1, h2, h3, h4, etc.
*/

.title {
  font-size: 1.5em;
}

/*
  Category: p
*/
.textbox-number {
  height: -moz-fit-content;
  width: 100%;
  margin: auto auto;
  font-size: 34em;
  box-sizing: inherit;
}

.textbox-psalm {
  height: -moz-fit-content;
  width: 100%;
  margin: auto auto;
  font-size: 8em;
  color: black;
  box-sizing: inherit;
}

.textbox-verse {
  height: auto;
  width: auto;
  padding: 4px;
  box-sizing: inherit;
}

/*
  Category: Multimedia + Animaitons
*/

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}
